<template>
  <div
    class="app-modal"
    :class="[{ active: show || showModal }, { default: defaultStyle }]"
  >
    <transition name="slide-down">
      <div
        v-if="show || showModal"
        class="app-modal__dialog"
        :class="useLargeSize === true ? 'size-xl' : ''"
      >
        <button
          class="btn app-modal__close"
          @click="closeModal()"
          v-if="!propsClose"
        >
          <img :src="require('@/assets/image/close.svg')" alt="close popup" />
        </button>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ModalLayout",
  props: {
    useLargeSize: {
      type: Boolean,
      default: false
    },
    defaultStyle: {
      type: Boolean,
      default: true
    },
    propsClose: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      if (this.showModal) {
        this.$emit("closeContinueDatePopup");
      }
      // document.querySelector("body").style.cssText =
      //   "height: unset; overflow-y: unset";
    },
    openModal() {
      this.show = true;
      // document.querySelector("body").style.cssText =
      //   "height: 100vh; overflow-y: hidden";
    }
  }
};
</script>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.default {
    .app-modal__dialog {
      width: 375px;
      max-width: 100%;
    }
  }

  &__dialog {
    position: relative;
    padding: 20px;
    background-color: #fff;

    &.size-xl {
      max-width: 503px;
      max-height: 75vh;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    img {
      width: 14px;
      display: block;
    }
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    // transform: translateY(-20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    // transform: translateY(0);
  }
}

@media only screen and (max-width: 374px) {
  .app-modal {
    &__dialog {
      padding: 20px 0;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .app-modal {
    &.default {
      .app-modal__dialog {
        // max-width: 100%;
        width: unset;
      }
    }
    &__dialog {
      border-radius: 5px;
    }

    &__close {
      img {
        width: 16px;
      }
    }
  }
}
</style>
